import { createMuiTheme } from '@material-ui/core/styles'
import { esES } from '@material-ui/core/locale';

const theme = createMuiTheme({
    recargasacuba: {
        naranja: 'rgb(238, 129, 60)',
        cubacel: 'rgb(0, 55, 141)',
        azul: 'rgb(101, 152, 228)',
        verde: 'rgb(103, 174, 85)',
    },
    palette: {
        secondary: {
            main: 'rgb(238, 129, 60)',
        }
    },
    typography: {
        fontFamily: 'MuseoSans,Raleway,Roboto',
    },
}, esES)

export default theme;