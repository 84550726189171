/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, image, preview }) {
  const { site, images } = useStaticQuery(
    graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath 
              childImageSharp {
                fixed(width: 1024) {
                  src
                  width
                  height
                }
              }
            }
          }
        }
        site {
          siteMetadata {
            title
            description
            author
            baseUrl
          }
        }
      }
    `
  )


  const imageToFind = image ? image : 'NOT_FOUND';
  const defaultWidth = 1024;
  const defaultHeight = 1024;
  const relativeImagePath = images.edges.find(item => {
    return imageToFind.includes(item.node.relativePath);
  })
  const metaDescription = description || site.siteMetadata.description
  const metaImage = image ? `${site.siteMetadata.baseUrl}${relativeImagePath.node.childImageSharp.fixed.src}` : `https://www.recargasacuba.app/logo_blog.png`
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: preview || metaDescription,
        },
        {
          property: `dc.title`,
          content: title,
        },
        {
          property: `dc.description`,
          content: metaDescription,
        },
        {
          property: `og:locale`,
          content: `es_ES`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:image:width`,
          content: image ? relativeImagePath.node.childImageSharp.fixed.width : defaultWidth,
        },
        {
          property: `og:image:height`,
          content: image ? relativeImagePath.node.childImageSharp.fixed.height : defaultHeight,
        },
        {
          property: `og:type`,
          content: `article`,
        },
        {
          property: `og:site_name`,
          content: `El blog de Cuba`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
