import { AppBar, Box, ButtonBase, makeStyles, Toolbar } from '@material-ui/core'
import { Link } from 'gatsby';
import React from 'react'
import Logo from '../../images/new_logo.png'

const useStyle = makeStyles(theme => ({
    toolbar: theme.mixins.toolbar,
    appBar: {
        background: theme.recargasacuba.naranja,
    },
    appBarLogo: {
        height: theme.mixins.toolbar.minHeight * 0.8,
    },
    appBarLogoWithIcon: {
        marginLeft: theme.spacing(8),
    },
    toolbarButton: {
        fontSize: theme.spacing(3),
        padding: theme.spacing(1)
    },
}));

const Navbar = (props) => {
    const classes = useStyle();

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                <Box display="flex" flexGrow="1" justifyContent="center">
                    <Link to="/">
                        <ButtonBase>
                            <img className={classes.appBarLogo} alt="Recargas MLC" src={Logo} />
                        </ButtonBase>
                    </Link>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default Navbar
