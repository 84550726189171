/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Navbar from "../utils/Navbar"
import { Container, makeStyles } from "@material-ui/core"
import theme from '../../theme/theme'
import { ThemeProvider } from "@material-ui/core"

const useStyle = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
}))

const Layout = ({ children }) => {
  const classes = useStyle();

  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <div className={classes.toolbar} />
      <Container maxWidth="md">
        {children}
      </Container>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
